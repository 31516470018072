import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectBookmarkedProductIds } from 'ducks/client/bookmarks';
import {
  selectPopularProductIds,
  selectRecommendedProductIds,
  selectShowTotalBooked,
  selectCategories,
  selectFeatures,
} from 'lib/util/privateMarketplace';
import { ProductSummaryShape } from 'lib/util/productSummaryShape';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { LabelType } from 'lib/themes/themes';

import { PMPProductCardView } from './PMPProductCardView';

interface Props {
  product: ProductSummaryShape;
  displayLabelTypes: LabelType[];
}

export const ProductCard = ({ displayLabelTypes, product }: Props) => {
  const { t } = useTranslation();

  const shouldShowTotalBooked = useSelector(selectShowTotalBooked);

  const popularProductIds = useSelector(selectPopularProductIds);
  const ranking = popularProductIds.indexOf(product.id) + 1;

  const recommendedProductIds = useSelector(selectRecommendedProductIds);
  const isRecommended = recommendedProductIds.includes(product.id);

  const categories = useSelector(selectCategories);
  const features = useSelector(selectFeatures);

  const bookmarks = useSelector(selectBookmarkedProductIds);
  const isBookmarked = bookmarks.includes(product.id);

  const currencyFormatter = useCurrencyFormatter();

  let discountText = '';
  if (product.bestDiscountPercent || product.bestDiscountGross) {
    discountText = t('{{discount}} OFF', {
      discount: product.bestDiscountPercent
        ? `${product.bestDiscountPercent}%`
        : currencyFormatter.format(product.bestDiscountGross),
    });
  }

  const tags = [
    ...categories
      .filter((category) => (category.product_ids || []).includes(product.id))
      .map((c) => c.name),
    ...features
      .filter((feature) => (feature.product_ids || []).includes(product.id))
      .map((f) => f.name),
    ...product.tags,
  ];

  return (
    <PMPProductCardView
      product={product}
      ranking={ranking}
      isRecommended={isRecommended}
      isBookmarked={isBookmarked}
      shouldShowTotalBooked={shouldShowTotalBooked}
      displayLabelTypes={displayLabelTypes}
      tags={tags}
      discountText={discountText}
    />
  );
};
